export class TeamMemberRewardRequest {
  constructor(response) {
    this.amount = response.amount;
    this.type = response.type;
    this.status = response.status;
    this.code = response.code;
  }
}

export class TeamMemberRewardsStats {
  constructor(stats) {
    this.available_reward_amount = stats.available_reward_amount;
    this.pending_reward_amount = stats.pending_reward_amount;
    this.used_reward_amount = stats.used_reward_amount;
  }

  clone() {
    return new TeamMemberRewardsStats(this);
  }

  decrementAvailableRewardAmount(amount) {
    this.available_reward_amount = this.available_reward_amount - amount;
  }
}
