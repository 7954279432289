import {useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {useTranslation} from "react-i18next";
import {projectService} from "src/services/project/project";
import {ProjectAssignment} from "src/entities/project/project-assignment";

export const CompletedSurveys = () => {
  const {t, i18n} = useTranslation();
  const [completedProjectAssignments, setCompletedProjectAssignments] = useState([]);

  useEffect(() => {
    projectService.listProjectAssignments("completed").then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setCompletedProjectAssignments(projects);
    });
  }, []);

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        <Typography variant="h5" sx={{mb: 2}}>
          {t("completedSurveys.completedSurveys")}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("completedSurveys.surveyName")}</TableCell>
                <TableCell>{t("completedSurveys.completedDate")}</TableCell>
                <TableCell>{t("completedSurveys.rewardStatus")}</TableCell>
                <TableCell>{t("completedSurveys.rewardAmount")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {completedProjectAssignments.map(projectAssignment => (
                <TableRow>
                  <TableCell>{projectAssignment.topic}</TableCell>
                  <TableCell>***</TableCell>
                  <TableCell>{projectAssignment.reward}</TableCell>
                  <TableCell>{JSON.stringify(projectAssignment.reward_status)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};
