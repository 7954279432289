import {Button, IconButton, InputAdornment, TextField} from "@mui/material";
import {Box} from "@mui/system";
import React, {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

function AccountInfoPasswordForm() {
  const {t} = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleRePasswordChange = e => {
    setRePassword(e.target.value);
    setPasswordError(password !== e.target.value ? t("auth.passwordsNotMatch") : "");   
  };
  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        m: 3,
        mt: 0,
      }}
    >
      <TextField
        sx={{width: "100%"}}
        label={t("auth.password")}
        variant="outlined"
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={handlePasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        sx={{width: "100%"}}
        label={t("auth.reEnterPassword")}
        variant="outlined"
        type={showPassword ? "text" : "password"}
        value={rePassword}
        onChange={handleRePasswordChange}
        error={!!passwordError}
        helperText={passwordError}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Button variant="contained" color="primary" type="submit" disabled={true}>
        {t("editInfo.changePassword")}
      </Button>
    </Box>
  );
}

export default AccountInfoPasswordForm;
