import {Button, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

const validateTC = tc => {
  const isValid = /^[1-9]\d{10}$/.test(tc); // T.C. must be 11 digits, cannot start with 0
  return isValid ? "" : "Invalid Turkish ID";
};

function AccountInfoBillingForm() {
  const {t} = useTranslation();

  const [isEditing, setIsEditing] = useState(false); // Track if the form is in edit mode
  const [iban, setIban] = useState("");
  const [ibanConfirmation, setIbanConfirmation] = useState("");
  const [ibanError, setIbanError] = useState("");
  const [tc, setTc] = useState("");
  const [tcError, setTcError] = useState("");
  const [address, setAddress] = useState("");

  const handleTcChange = e => {
    const value = e.target.value;
    setTc(value);
    setTcError(validateTC(value));
  };

  const handleAddressChange = e => {
    setAddress(e.target.value);
  };

  const handleIbanChange = event => {
    const value = event.target.value;
    setIban(value);
    // Check if the IBAN and confirmation match
    if (value !== ibanConfirmation) {
      setIbanError("IBANs do not match.");
    } else {
      setIbanError("");
    }
  };

  const handleIbanConfirmationChange = event => {
    const value = event.target.value;
    setIbanConfirmation(value);
    // Check if the IBAN and confirmation match
    if (value !== iban) {
      setIbanError("IBANs do not match.");
    } else {
      setIbanError("");
    }
  };
  const toggleEditMode = () => {
    if (isEditing) {
      // Here you would typically save the data to a server
      console.log("Saving data...");
    }
    setIsEditing(!isEditing);
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        m: 3,
        mt: 0,
      }}
    >
      <Typography variant="body2">{t("paymentInfo.paymentAlert")}</Typography>

      <TextField
        sx={{width: "100%"}}
        label={t("editInfo.fullName")}
        variant="outlined"
        defaultValue="John Doe"
        disabled={!isEditing}
      />
      <TextField
        sx={{width: "100%"}}
        label={t("paymentInfo.bankName")}
        variant="outlined"
        defaultValue={t("paymentInfo.bankName")}
        disabled={!isEditing}
      />

      <TextField
        sx={{width: "100%"}}
        label=" Iban (TR XX XXXX XXXX XXXX XXXX XXXX)"
        variant="outlined"
        value={iban}
        onChange={handleIbanChange}
        error={!!ibanError}
        helperText={ibanError}
        disabled={!isEditing}
      />
      <TextField
        sx={{width: "100%"}}
        label={t("paymentInfo.ibanConfirmation")}
        variant="outlined"
        value={ibanConfirmation}
        onChange={handleIbanConfirmationChange}
        error={!!ibanError}
        helperText={ibanError}
        disabled={!isEditing}
      />

      <TextField
        sx={{width: "100%"}}
        label={t("editInfo.identityNumber")}
        variant="outlined"
        value={tc}
        onChange={handleTcChange}
        error={!!tcError}
        helperText={tcError}
        disabled={!isEditing}
      />

      <TextField
        sx={{width: "100%"}}
        label={t("editInfo.address")}
        variant="outlined"
        multiline
        rows={4}
        value={address}
        onChange={handleAddressChange}
        disabled={!isEditing}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
        }}
      >
        <Button
          variant="contained"
          sx={{backgroundColor: "#ad1445", width: "100%"}}
          onClick={toggleEditMode}
        >
          {isEditing
            ? t("editInfo.saveAccountInformation")
            : t("editInfo.editAccountInformation")}
        </Button>
      </Box>
    </Box>
  );
}

export default AccountInfoBillingForm;
