import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {Box} from "@mui/system";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "src/hooks/use-auth";
import {userService} from "src/services/user";
import CircularProgress from "@mui/material/CircularProgress";

function AccountInfoUserForm() {
  const {t} = useTranslation();
  const auth = useAuth();

  const userMail =
    auth?.state?.user?.email || auth?.state?.user?.data?.email || auth?.state?.email;

  const [isEditing, setIsEditing] = useState(false); // Track if the form is in edit mode
  const [gender, setGender] = useState("");
  const [province, setProvince] = useState("");
  const [country, setCountry] = useState("Türkiye");
  const [email, setEmail] = useState(userMail);
  const [emailError, setEmailError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [open, setOpen] = useState(false);
  const [loadingDeleteAccountButton, setLoadingDeleteAccountButton] = useState(false);

  const handleOpenDeleteDialog = () => {
    setOpen(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpen(false);
  };

  const handleDeleteAccount = () => {
    setLoadingDeleteAccountButton(true);
    userService.deactivateUser().then(_ => {
      auth.signOut().then(_ => {
        setLoadingDeleteAccountButton(false);
      });
    });
  };

  const handleCountryChange = event => {
    setCountry(event.target.value);
    if (event.target.value !== "Türkiye") {
      setProvince(""); // Clear province if country is not Turkey
    }
  };

  const handleEmailChange = event => {
    const value = event.target.value;
    setEmail(value);

    // Regular expression to match the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(value)) {
      setEmailError(""); // Clear any previous error
    } else {
      setEmailError(t("auth.enterValidEmailAddress"));
    }
  };

  const formatPhoneNumber = value => {
    // Remove all non-digit characters
    const digits = value.replace(/\D/g, "");

    // Format the phone number
    let formattedNumber = "";
    if (digits.length > 0) {
      formattedNumber = digits.substring(0, 4); // First 4 digits
    }
    if (digits.length > 4) {
      formattedNumber += " " + digits.substring(4, 7); // Next 3 digits
    }
    if (digits.length > 7) {
      formattedNumber += " " + digits.substring(7, 9); // Next 2 digits
    }
    if (digits.length > 9) {
      formattedNumber += " " + digits.substring(9, 11); // Last 2 digits
    }

    return formattedNumber;
  };

  const handlePhoneNumberChange = event => {
    const value = event.target.value;
    const formattedValue = formatPhoneNumber(value);

    // Regular expression to match the phone number format
    const phoneRegex = /^05\d{2} \d{3} \d{2} \d{2}$/;

    if (phoneRegex.test(formattedValue)) {
      setPhoneError(""); // Clear any previous error
    } else {
      setPhoneError("Please enter a valid phone number in the format 0532 052 52 82.");
    }

    setPhoneNumber(formattedValue);
  };

  const toggleEditMode = () => {
    if (isEditing) {
      // Here you would typically save the data to a server
      console.log("Saving data...");
    }
    setIsEditing(!isEditing);
  };

  const provinces = [
    {code: "01", name: "Adana"},
    {code: "02", name: "Adıyaman"},
    {code: "03", name: "Afyonkarahisar"},
    {code: "04", name: "Ağrı"},
    {code: "05", name: "Amasya"},
    {code: "06", name: "Ankara"},
    {code: "07", name: "Antalya"},
    {code: "08", name: "Artvin"},
    {code: "09", name: "Aydın"},
    {code: "10", name: "Balıkesir"},
    {code: "11", name: "Bilecik"},
    {code: "12", name: "Bingöl"},
    {code: "13", name: "Bitlis"},
    {code: "14", name: "Bolu"},
    {code: "15", name: "Burdur"},
    {code: "16", name: "Bursa"},
    {code: "17", name: "Çanakkale"},
    {code: "18", name: "Çankırı"},
    {code: "19", name: "Çorum"},
    {code: "20", name: "Denizli"},
    {code: "21", name: "Diyarbakır"},
    {code: "22", name: "Edirne"},
    {code: "23", name: "Elazığ"},
    {code: "24", name: "Erzincan"},
    {code: "25", name: "Erzurum"},
    {code: "26", name: "Eskişehir"},
    {code: "27", name: "Gaziantep"},
    {code: "28", name: "Giresun"},
    {code: "29", name: "Gümüşhane"},
    {code: "30", name: "Hakkari"},
    {code: "31", name: "Hatay"},
    {code: "32", name: "Isparta"},
    {code: "33", name: "Mersin"},
    {code: "34", name: "İstanbul"},
    {code: "35", name: "İzmir"},
    {code: "36", name: "Kars"},
    {code: "37", name: "Kastamonu"},
    {code: "38", name: "Kayseri"},
    {code: "39", name: "Kırklareli"},
    {code: "40", name: "Kırşehir"},
    {code: "41", name: "Kocaeli"},
    {code: "42", name: "Konya"},
    {code: "43", name: "Kütahya"},
    {code: "44", name: "Malatya"},
    {code: "45", name: "Manisa"},
    {code: "46", name: "Kahramanmaraş"},
    {code: "47", name: "Mardin"},
    {code: "48", name: "Muğla"},
    {code: "49", name: "Muş"},
    {code: "50", name: "Nevşehir"},
    {code: "51", name: "Niğde"},
    {code: "52", name: "Ordu"},
    {code: "53", name: "Rize"},
    {code: "54", name: "Sakarya"},
    {code: "55", name: "Samsun"},
    {code: "56", name: "Siirt"},
    {code: "57", name: "Sinop"},
    {code: "58", name: "Sivas"},
    {code: "59", name: "Tekirdağ"},
    {code: "60", name: "Tokat"},
    {code: "61", name: "Trabzon"},
    {code: "62", name: "Tunceli"},
    {code: "63", name: "Şanlıurfa"},
    {code: "64", name: "Uşak"},
    {code: "65", name: "Van"},
    {code: "66", name: "Yozgat"},
    {code: "67", name: "Zonguldak"},
    {code: "68", name: "Aksaray"},
    {code: "69", name: "Bayburt"},
    {code: "70", name: "Karaman"},
    {code: "71", name: "Kırıkkale"},
    {code: "72", name: "Batman"},
    {code: "73", name: "Şırnak"},
    {code: "74", name: "Bartın"},
    {code: "75", name: "Ardahan"},
    {code: "76", name: "Iğdır"},
    {code: "77", name: "Yalova"},
    {code: "78", name: "Karabük"},
    {code: "79", name: "Kilis"},
    {code: "80", name: "Osmaniye"},
    {code: "81", name: "Düzce"},
  ];
  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        m: 3,
        mt: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{width: "100%"}}
          label={t("editInfo.mail")}
          variant="outlined"
          value={email}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
          disabled={!isEditing}
        />
        <TextField
          sx={{width: "100%"}}
          label={t("editInfo.phoneNumber")}
          variant="outlined"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          error={!!phoneError}
          helperText={phoneError}
          disabled={!isEditing}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          justifyContent: "space-between",
        }}
      >
        <FormControl sx={{width: "100%"}} variant="outlined" disabled={!isEditing}>
          <InputLabel>{t("editInfo.gender")}</InputLabel>
          <Select value={gender} onChange={e => setGender(e.target.value)} label="Gender">
            <MenuItem value="">
              <em>{t("none")}</em>
            </MenuItem>
            <MenuItem value="male">{t("male")}</MenuItem>
            <MenuItem value="female">{t("female")}</MenuItem>
            <MenuItem value="other">{t("other")}</MenuItem>
          </Select>
        </FormControl>
        <TextField
          sx={{width: "100%"}}
          label={t("editInfo.birthday")}
          variant="outlined"
          type="date" // Set input type to date
          InputLabelProps={{shrink: true}} // Ensure label shrinks to fit date input
          defaultValue="2001-01-01" // Set default value in YYYY-MM-DD format
          disabled={!isEditing}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          justifyContent: "space-between",
        }}
      >
        <TextField
          sx={{width: "100%"}}
          label={t("editInfo.country")}
          variant="outlined"
          value={country}
          onChange={handleCountryChange}
          disabled={!isEditing}
        />
        {country === "Türkiye" && (
          <FormControl sx={{width: "100%"}} variant="outlined" disabled={!isEditing}>
            <InputLabel>{t("editInfo.province")}</InputLabel>
            <Select
              value={province}
              onChange={e => setProvince(e.target.value)}
              label="Province"
            >
              <MenuItem value="">
                <em>{t("none")}</em>
              </MenuItem>
              {provinces.map(province => (
                <MenuItem key={province.code} value={province.name}>
                  {province.code} - {province.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 3,
        }}
      >
        <Button
          variant="contained"
          sx={{backgroundColor: "#ad1445", width: "100%"}}
          onClick={toggleEditMode}
        >
          {isEditing
            ? t("editInfo.saveAccountInformation")
            : t("editInfo.editAccountInformation")}
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2, // Adds space between the buttons
          mt: 3,
        }}
      >
        {/* Delete Account */}
        <Button
          variant="outlined"
          color="error"
          sx={{mt: 2, width: "100%"}}
          onClick={handleOpenDeleteDialog}
        >
          {t("editInfo.deleteAccount")}
        </Button>

        {/* Delete Account Dialog */}
        <Dialog open={open} onClose={handleCloseDeleteDialog}>
          <DialogTitle>{t("editInfo.deleteAccountConfirmation")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("editInfo.deleteAccountConfirmationDescription")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              {t("editInfo.cancel")}
            </Button>
            <Button onClick={handleDeleteAccount} color="error" autoFocus>
              {loadingDeleteAccountButton ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("editInfo.delete")
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}

export default AccountInfoUserForm;
