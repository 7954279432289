import {questionFactory} from "src/entities/project/survey/question-factory";
import {displayLogic} from "src/pages/survey/check-question/display-logic";

export class Survey {
  constructor(survey) {
    this.id = survey.id;
    this.language = survey.language;
    this.show_previous_button = survey.show_previous_button;
    this.is_active = survey.is_active;
    this.inactive_survey_message = survey.inactive_survey_message;
    this.end_page_message = survey.end_page_message;
    this.allQuestionsMap = {}; // id -> Question map
    this.currentBlock = null;
    this.nextFlowElementId = null;
    this.totalQuestionsCount = 0;
  }

  setTotalQuestionsCount(totalQuestionsCount) {
    this.totalQuestionsCount = totalQuestionsCount;
  }

  setNextFlowElementId(nextFlowElementId) {
    this.nextFlowElementId = nextFlowElementId;
  }

  extendAllQuestions(block) {
    const blockQuestions = block.pages.reduce((acc, page) => page.getQuestionIdMap(), {});
    this.allQuestionsMap = {
      ...this.allQuestionsMap,
      ...blockQuestions,
    };
  }

  setCurrentBlock(block) {
    this.currentBlock = block;
  }

  getCurrentBlockQuestionCount() {
    if (this.currentBlock) {
      return this.currentBlock.pages.reduce(
        (acc, page) => page.getPageQuestionCount(),
        0,
      );
    } else {
      return 0;
    }
  }

  getCurrentBlockPageCount() {
    if (this.currentBlock) {
      return this.currentBlock.pages.length;
    } else {
      return 0;
    }
  }

  getCurrentBlockQuestionsByPageIndex(pageIndex, answersMap) {
    return this.currentBlock.pages[pageIndex].questions.filter(question =>
      question.isValid(this.allQuestionsMap, answersMap),
    );
  }
}

export class Block {
  constructor(pages) {
    this.pages = this.preparePages(pages);
  }

  preparePages(pages) {
    const shuffleQuestions = [];
    for (const page of pages) {
      shuffleQuestions.push(...page.questions.filter(question => question.is_random));
    }
    const updatedBlockPages = [];
    for (const page of pages) {
      const tempPage = new Page({questions: []});
      for (const question of page.questions) {
        if (question.is_random) {
          const randomIndex = Math.floor(Math.random() * shuffleQuestions.length);
          const randomQuestion = shuffleQuestions[randomIndex];
          tempPage.questions.push(new Question(randomQuestion));
        } else {
          tempPage.questions.push(new Question(question));
        }
      }
      updatedBlockPages.push(tempPage);
    }
    return updatedBlockPages;
  }
}

export class Page {
  constructor(page) {
    this.questions = page.questions.map(question => new Question(question));
  }

  getQuestionIdMap() {
    return this.questions.reduce((acc, question) => ({[question.id]: question}), {});
  }

  getPageQuestionCount() {
    return this.questions.length;
  }
}

export class Question {
  constructor(question) {
    this.id = question.id;
    this.label = question.label;
    this.text = question.text;
    this.type = question.type;
    this.index = question.index;
    this.is_random = question.is_random;
    this.display_logics = question.display_logics;
    this.skip_logics = question.skip_logics;

    Object.assign(this, questionFactory(question));
  }

  /**
   * @questionsMap: Map<String, Question>
   * @answers: Map<String, Answer>
   * */
  isValid(questionsMap, answersMap) {
    if (displayLogic(this, questionsMap, answersMap) === false) {
      return false;
    }
    return true;
  }
}
