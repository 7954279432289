import React, {useCallback, useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Grid, Paper} from "@mui/material";
import {useTranslation} from "react-i18next";
import {projectService} from "src/services/project/project";
import {ProjectAssignment} from "src/entities/project/project-assignment";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";

export const OpenSurveys = () => {
  const {t, i18n} = useTranslation();
  const [soonProjectAssignments, setSoonProjectAssignments] = useState([]);
  const [activeProjectAssignments, setActiveProjectAssignments] = useState([]);
  const router = useRouter();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    projectService.listProjectAssignments("soon").then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setSoonProjectAssignments(projects);
    });
    projectService.listProjectAssignments("active").then(response => {
      const projects = response.results.map(res => new ProjectAssignment(res));
      setActiveProjectAssignments(projects);
    });
  }, []);

  const handleStartButton = useCallback(projectId => {
    router.push(paths.survey.replace(":id", projectId));
  }, []);

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        {/* Summary Boxes */}
        <Grid container spacing={isMobile ? 2 : 4} sx={{mb: 4}}>
          <Grid item xs={12} sm={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("myCompletedSurvey")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                2
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("totalRewardsReceived")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                2
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Open Surveys Section */}
        <Typography variant="h5" sx={{mb: 2}}>
          {t("openSurveys")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "left",
            gap: 2,
            mb: 4,
          }}
        >
          {/* Survey Cards */}
          {soonProjectAssignments.map(projectAssignment => (
            <Card sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
              <CardContent>
                <Typography variant="h6">{projectAssignment.topic}</Typography>
                <Typography variant="body2">
                  {t("surveyFeature.duration")} {projectAssignment.duration}
                </Typography>
                <Typography variant="body2">
                  {t("surveyFeature.rewardPoint")} {projectAssignment.reward}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    mt: 2,
                  }}
                  disabled={true}
                >
                  Coming Soon
                </Button>
              </CardContent>
            </Card>
          ))}
          {activeProjectAssignments.map(projectAssignment => (
            <Card sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
              <CardContent>
                <Typography variant="h6">{projectAssignment.topic}</Typography>
                <Typography variant="body2">
                  {t("surveyFeature.duration")} {projectAssignment.duration}
                </Typography>
                <Typography variant="body2">
                  {t("surveyFeature.rewardPoint")} {projectAssignment.reward}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    mt: 2,
                  }}
                  onClick={_ => handleStartButton(projectAssignment.projectId)}
                >
                  {t("startButton")}
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/* Expired Surveys Section */}
        <Typography variant="h5" sx={{mb: 2}}>
          {t("expiredSurvey")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "left",
            gap: 2,
            mb: 4,
          }}
        >
          {/* Survey Cards */}
          <Card sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
            <CardContent>
              <Typography variant="h6">Askerlik 2</Typography>
              <Typography variant="body2">{t("surveyFeature.duration")}</Typography>
              <Typography variant="body2">{t("surveyFeature.rewardPoint")}</Typography>
            </CardContent>
          </Card>

          <Card sx={{width: isMobile ? "100%" : "24%", minHeight: "150px"}}>
            <CardContent>
              <Typography variant="h6">Ekonomi</Typography>
              <Typography variant="body2">{t("surveyFeature.duration")}</Typography>
              <Typography variant="body2">{t("surveyFeature.rewardPoint")}</Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Container>
  );
};
