export const enTranslations = {
  mySurveys: "My Surveys",
  myCompletedSurvey: "My Completed Survey",
  totalRewardsReceived: "Total Rewards Received",
  openSurveys: "Open Surveys",
  expiredSurvey: "Expired Survey",
  completedSurveys: "Completed Surveys",
  accountInfo: "Account Info",
  mainPage:{
    howToUse:"How to use our platform?",
    inNumbers:"In Numbers",
    register:"Register",
    fillInSurveys:"Fill In Surveys",
    winPrize:"Win Prize",
    registerDescr:"As a first step, register on our platform and get access to the surveys inside.",
    fillInSurveysDescr:"Log in to the platform and fill out the survey that interests you.",
    winPrizeDescr:"Earn various rewards as you complete surveys. Rewards can include discounts from different brands, cash and more.",
    participantPool:"Participant Pool",
    surveyDiversity:"Survey Diversity",
    rewardDiversity:"Reward Diversity",
    support:"Support",
    participantPoolDescr:"Many people we can assign to your surveys",
    surveyDiversityDescr:"Too many draft surveys",
    rewardDiversityDescr:"Gift cards, discounts, cash prizes and more you can win",
    supportDescr:"Continuous support for your surveys",
    FAQ:"FAQ",
  },
  rewards: {
    rewards: "Rewards",
    availablePoints: "Available Points",
    usedPoints: "Used Points",
    amount: "Amount: ",
    giftCard: "Hediye Kartı",
    receiveGifts: "Receive Gifts",
    sendToIban: "Send To IBAN",
    previousRewardRequests: "Previous Reward Requests",
    requestedDate: "Requested Date",
    surveyName: "Survey Name",
    requestedStatus: "	Requested Status",
    pointUsed: "Point Used",
    rewardType: "Reward Type",
    rewardSentDate: "Reward Sent Date",
    giftCode: "Gift Code",
    noRequests: "No reward requests submitted yet.",
  },
  completedSurveys: {
    completedSurveys: "Completed Surveys",
    surveyName: "Survey Name",
    completedDate: "Completed Date",
    rewardType: "Reward Type",
    surveyStatus: "Survey Status",
    rewardStatus: "Reward Status",
    rewardAccess: "Reward Access",
    rewardAmount: "Reward Amount",

    ongoingStatus: "Ongoing",
    completedStatus: "Completed",
  },

  tableHeaders: {
    title: "Title",
    questions: "Questions",
    responses: "Responses",
    completion: "Completion",
    created: "Created",
    updated: "Updated",
    actions: "Actions",
  },
  surveyFeature: {
    duration: "Duration",
    rewardPoint: "Reward Point:",
  },
  prev: "Previous Button",
  next: "Next Button",
  startButton: "Start",
  noDataFound: "No data found",
  settings: {
    surveyTitle: "Survey Title",
    live: "Live",
  },
  buttons: {
    downloadDraftExcel: "Draft Excel File",
    UploadDraftExcel: "Edited Excel File",
    yourDraftExcel: "Your Excel File",
  },
  importExcelButtonLabel: "Survey From Excel File",
  importExcelDialogTitle: "Survey From Excel File",
  importExcelNameLabel: "Survey Name",
  importFileButtonLabel: "Import File",
  createSurveyButtonLabel: "Create",
  createSurveyDialogTitle: "Create a survey from scratch",
  nameInputLabel: "Name",
  createButtonLabel: "Create",
  cancelButtonLabel: "Back",
  copyLink: "Copy Link",
  copySurvey: "Copy Survey",
  delete: "Delete",
  deleteSurveyTitle: "Delete Survey",
  DeleteSurveyConfirmation:
    "{{surveyTitle}} survey will be deleted permanently. Are you sure?",

  cancel: "Cancel",
  sections: {
    survey: "Survey",
    preview: "Preview",
    distributions: "Distributions",
    results: "Results",
    analyticsDashboard: "Analytics Dashboard",
  },
  basicSettings: {
    title: "Basic Settings",
    language: "Language",
    languageDescription:
      "We recommend selecting the language you wrote your survey in. Your response data will also be displayed in this language.",
    previousButton: "Previous Button",
    previousButtonDescription: "Enable/Disable previous button.",
    ipBlock: "IP Block",
    ipBlockDescription: "Block multiple responses from the same IP.",
  },

  writeSomething: "Write something",
  unavailableSurveyMessage: "Unavailable Survey Message",
  buttonLabels: {
    update: "Update",
    reset: "Reset",
  },
  blockSettings: {
    editBlock: "Edit Block",
    displayLogicWarning:
      "Attempting to use display logic and randomization within the same block. This combination may not function as expected.",
    randomizeQuestions: "Randomize Questions",
    addPageBreak: "Add Page Break to all Questions",
    makeAllRequired: "Make All Questions Required",
  },
  none: "None",
  male: "Male",
  female: "Female",
  other: "Other",
  all: "All",

  editInfo: {
    fullName: "Fullname",
    editInfo: "Edit Info",
    mail: "Email (info@galataanket.com)",
    phoneNumber: "Phone number (0532 000 00 00)",
    gender: "Gender",
    birthday: "Birthday",
    country: "Country",
    province: "Province",
    editAccountInformation: "Edit Account Information",
    saveAccountInformation: "Save Account Information",
    accountInformation: "Account Information",
    accountInformationDesc:
      "Manage your personal information such as name, email, and contact details in this section.",

    changePassword: "Change Password",
    changePasswordDesc: "Update your account password to ensure your account's security.",

    cancel: "Cancel",
    delete: "Delete",
    changeCommunicationPreferences: "Change Communication Preferences",
    communicationPreferences: "Change Communication Preferences",
    communicationPreferencesDesc:
      "Set your notification and communication preferences here, including email and SMS settings.",

    communicationPreferencesDirective:
      "Control how we communicate with you. Enable or disable notifications as you prefer.",
    receiveEmailNotification: "Receive notifications via email.",
    receiveSMSNotification: "Receive notifications via SMS.",
    receiveOurUpdates: "Receive our latest news and updates.",

    emailNotifications: "Email Notifications",
    SMSNotifications: "SMS Notifications",
    newsletterSubscription: "Newsletter Subscription",

    changesWillBeSaveAutomatically:
      "Changes will be save automatically as you toggle your preferences.",

    paymentInformation: "Payment Information",
    paymentInformationDesc:
      "View and manage your payment methods, billing details, and transaction history.",

    deleteAccountConfirmation: "Are you sure you want to delete your account?",
    deleteAccountConfirmationDescription:
      "Deleting your account is irreversible. All your data will be permanently removed. Are you sure you want to proceed?",
    deleteAccount: "Delete Account",
    changePasswordConfirmation: "Are you sure you want to change your password?",
    changePasswordConfirmationDescription:
      "Changing your password will require you to log in again. Are you sure you want to proceed?",
    identityNumber: "Identity Number",
    address: "Address",
  },
  paymentInfo: {
    paymentAlert:
      "In order to send money to the bank account, the following information must be filled in.",
    bankName: "Bank Name",
    ibanConfirmation: " Iban Confirmation (TR XX XXXX XXXX XXXX XXXX XXXX)",
  },
  editor: {
    endPageLabel: "End Page",
    createBlockTooltip: "Create Question Block",
    editBlockTooltip: "Edit Question Block",
    deleteBlockTooltip: "Delete Question Block",
    blockDeletionConfirmation: "block will be permanently deleted. Are you sure?",
  },
  downloadResults: "Download Results",
  resetSurvey: "Reset Survey",
  questionLabel: "Question Label",
  duplicate: "Duplicate",
  shuffleQuestion: "Shuffle Question",
  deleteQuestion: "Delete Question",
  ignoreRequire: "Ignore Require",
  ignorePreviewData: "Ignore Preview Data",
  addOption: "Add Option",

  label: "Default Question Label",
  choice1: "Choice 1",
  choice2: "Choice 2",
  choice3: "Choice 3",
  defaultQuestion: "Default Question Title",
  save: "Save",
  reset: "Reset",

  EditQuestion: "Edit Question",
  QuestionType: "Question Type",
  Text: "Text",
  Settings: "Settings",
  Behaviours: "Behaviours",
  MultipleChoice: "Multiple Choice",
  ShortText: "Short Text",
  TextGraphic: "Text / Graphic",
  SurveyQuestion: "Survey Question",
  EmbeddedDataField: "Embedded Data Field",
  EditQuestionTextDialogTitle: "Edit Question Text",
  PlaceholderWriteSomething: "Write something",
  CancelButton: "Cancel",

  SaveButton: "Save",
  RequiredLabel: "Required",
  ContentTypeLabel: "Content Type",
  TextLabel: "Text",
  NumberLabel: "Number",
  EmailAddressLabel: "Email Address",
  RequiredQuestionError: "This question is required.",
  textInputTypeError: "Text input is invalid.",
  integerInputTypeError: "Number input is invalid.",
  emailInputTypeError: "Email input is invalid.",
  DisplayLogic: "Display Logic",
  DownloadQRCode: "Download QR Code",
  CopyLink: "Copy Link",
  AddElement: "Add Element",
  Link: "Link",
  QRCode: "QR Code",
  CreateQuestion: "Create Question",
  AddPageBreak: "+ Add Page Break",
  LogoutButton: "Logout",
  AddBlock: "Add Block",
  AddBranch: "Add Branch",
  successfullyUpdated: "Successfully Updated",

  builder: "Builder",
  surveyFlow: "Survey flow",
  surveyOptions: "Survey options",
  enabled: "Enabled",

  auth: {
    login: "Log in",
    register: "Register",
    emailIsRequired: "Please enter your email address.",
    passwordIsRequired: "Please enter your password",
    alreadyhaveaccount: "Already Have Account",
    mustBeValidEmail: "Must be a valid email",
    emailAddressOrPhoneNumber: "Email Address or Phone Number",
    enterValidEmailAddress: "Please enter a valid email address.",

    emailOrPassIncorrect: "Email or password is incorrect, Please try again.",
    resetPassword: {
      title: "Reset Password",
      textLabel: "Email Address or Phone Number",
      buttonLabel: "Send activation link",
      invalidInputMessage: "Please provide a valid email or phone number (05329993377).",
      confirmationMessage:
        "If your info is in the system, you'll get an activation email/SMS. Check your email or phone. For questions, contact katilimcihavuzu@galataanket.com.",
    },
    forgotpasswordtitle: "Renew Password",
    forgotpasswordlink: "Sent a new password",
    emailAddress: "Email or phone number",
    password: "Password",
    Login: "Login",
    continue: "Send activation link",
    sendnewpassword: "Send a new password",
    sentinfo:
      "If your info isn't in the system, you'll get an activation email/SMS. Check your email or phone. For questions, contact katilimcihavuzu@galataanket.com.",
    passwordsentinfo:
      "If your info isn't in the system, you'll get a new password. You can change it from account settings again. For questions, contact katilimcihavuzu@galataanket.com.",
    forgotPassword: "Forgot password?",
    noAccount: "Don't have an account?",
    alreadyhaveaccount: "If you already activated your account:",
    reEnterPassword: "Re-enter Password",
    passwordsNotMatch: "Passwords do not match",
  },
  companyDescription:
    "Galata Anket is an academic and market research company that integrates digital survey tools and provides data-driven consultancy to its clients.",
  platformTour: "Platform Tour",

  professionalSurveyTeam: "A professional team for the most reliable survey experience!",
  dedicatedResearchAndAnalysis:
    "Our team of expert researchers and data analysts works diligently to design and manage surveys tailored to each customer's specific needs and objectives. Our aim is to gather accurate, reliable, and actionable data through online surveys or focus groups.",
  notOnlyDataCollection: "More than just data!",
  clientReportingAndRecommendations:
    "In addition to providing our customers with detailed reports and analyses, we offer alternative suggestions on how they can utilize the compiled information to enhance their organizations and bring them closer to their goals. Furthermore, we present the most accurate planning for implementing these suggestions.",
  dataDrivenDecisions: "Growth with Feedback: Stand Out in Competition with the Voice of Customers",
  commitmentToClientSuccess:
    "We strive to make our customers more visible in the competitive market and enable them to hear their own voice of customers more clearly by providing them with high-quality data. Join our mission and discover the power of listening to your customers' feedback!",
  messageSent: "Your message has been sent!",
  messageSendError: "Failed to send message. Please try again later.",
  welcomeContactPage: "Welcome to Our Contact Page!",
  contactInvitation:
    "Feel free to reach out to us with any questions or comments. We’d love to hear from you!",
  contactUs: "Contact Us",
  name: "Name",
  surname: "Surname",
  mail: "Mail",
  message: "Message",
  sending: "Sending ...",
  sendMessage: "Send Message",
  needHelp: "Need Help?",
  home: "Home",
  aboutUs: "About Us",
  contact: "Contact",
  platform: "Platform",
  pages: "Pages",
  websiteDisclosure: "Website Disclosure Text",
  cookiePrivacyPolicy: "Cookie and Privacy Policy",
  dataSubjectApplicationForm: "Data Subject Application Form",
  ourAddress: "Our Address",
  followUs: "Follow Us",
  rightsReserved: "Galata Survey. All rights reserved.",
};
