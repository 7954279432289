import axiosInstance from "src/services/axiosInstance";

class TeamMemberService {
  async listTeamMemberRewardRequests() {
    return await axiosInstance
      .get("/team-members/reward-requests", {params: {page: 1}})
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async getTeamMemberRewardsStats() {
    return await axiosInstance
      .get("/team-members/rewards/stats")
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async createTeamMemberRewardRequests(payload) {
    return await axiosInstance
      .post("/team-members/reward-requests", payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const teamMemberService = new TeamMemberService();
