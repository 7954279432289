import axiosInstance from "src/services/axiosInstance";

class ProjectService {
  async getProjectBlockQuestions(projectId, elementId) {
    return await axiosInstance
      .get(
        `/projects/${projectId}/questions${elementId ? `?element_id=${elementId}` : ""}`,
      )
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async getProjectDetails(uuid) {
    return await axiosInstance
      .get(`/projects/${uuid}`)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async listProjectAssignments(status) {
    return await axiosInstance
      .get("/projects", {params: {status: status}})
      .then(projects => {
        return projects?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const projectService = new ProjectService();
