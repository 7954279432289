import {useAuth} from "src/hooks/use-auth";
import {useState} from "react";
import {Stack} from "@mui/system";
import Typography from "@mui/material/Typography";
import {Link, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";

const initialValues = {
  emailOrPhone: "",
  submit: null,
};

const validationSchema = Yup.object({
  emailOrPhone: Yup.string()
    .test(
      "emailOrPhone",
      "Geçerli bir email veya telefon numarası  (05329993377) girilmelidir.",
      value => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^05\d{9}$/;
        return emailRegex.test(value) || phoneRegex.test(value);
      },
    )
    .required("Bu alanın doldurulması gereklidir."),
});

export const Register = () => {
  const {t} = useTranslation();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState(null); // State for notification message

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helpers) => {
      try {
        setLoading(true);
        auth.register(values.emailOrPhone).then(response => {
          setLoading(false);
          setNotification(
            "If your info isn't in the system, you'll get an activation email/SMS. Check your email or phone. For questions, contact katilimcihavuzu@galataanket.com.",
          );

          helpers.setSubmitting(false);
        });
      } catch (err) {
        setLoading(false);
        helpers.setSubmitting(false);
        console.error(err);
      }
    },
  });

  return (
    <>
      {notification ? (
        <Stack sx={{mb: 4}} spacing={1}>
          <Typography variant="body2">{notification}</Typography>
        </Stack>
      ) : (
        <>
          <Stack sx={{mb: 4}} spacing={1}>
            <Typography variant="h5">{t("auth.register")}</Typography>
          </Stack>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={3}>
              <TextField
                autoFocus
                error={!!(formik.touched.emailOrPhone && formik.errors.emailOrPhone)}
                fullWidth
                helperText={formik.touched.emailOrPhone && formik.errors.emailOrPhone}
                label={t("auth.emailAddressOrPhoneNumber")}
                name="emailOrPhone"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.emailOrPhone}
              />
            </Stack>
            <Button
              fullWidth
              disabled={formik.isSubmitting}
              sx={{mt: 3}}
              size="large"
              type="submit"
              variant="contained"
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("auth.continue")
              )}
            </Button>
            <Stack sx={{ mt: 3, mb: 4 }} spacing={3}>
            <Typography underline="hover" variant="subtitle2" color="text.secondary">
          {t("auth.alreadyhaveaccount")}&nbsp;
          <Link href="/auth/login" underline="hover" variant="subtitle2">
            {t("auth.login")}
          </Link>
        </Typography>
        </Stack>
          </form>
        </>
      )}
    </>
  );
};
