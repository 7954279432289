export class ProjectAssignment {
  constructor(projectAssignment) {
    this.id = projectAssignment.id;
    this.projectId = projectAssignment.project_id;
    this.topic = projectAssignment.topic;
    this.duration = projectAssignment.duration;
    this.reward = projectAssignment.reward;
    this.reward_status = projectAssignment.reward_status;
    this.project_status = projectAssignment.status;
  }
}
