import {useState} from "react";
import {
  Container,
  Typography,
  Box,
  Tabs,
  Tab,
  Paper,
  Alert,
  AlertTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
  Chip,
  useTheme,
  Divider,
} from "@mui/material";
import {
  Store,
  AccessTime,
  CreditCard,
  Block,
  CardGiftcard,
  Info,
  LocalOffer,
  ShoppingCart,
  Payment,
} from "@mui/icons-material";

const InstructionItem = ({icon: Icon, text, subtitle, chipText}) => {
  const theme = useTheme();

  return (
    <ListItem
      sx={{
        mb: 2,
        p: 2,
        borderRadius: 2,
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.02)",
          transform: "translateX(8px)",
        },
      }}
    >
      <ListItemIcon>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50%",
            p: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon sx={{color: "white"}} />
        </Box>
      </ListItemIcon>
      <ListItemText
        primary={
          <Box sx={{display: "flex", alignItems: "center", gap: 2}}>
            <Typography variant="body1" fontWeight="500">
              {text}
            </Typography>
            {chipText && (
              <Chip label={chipText} size="small" color="primary" variant="outlined" />
            )}
          </Box>
        }
        secondary={subtitle}
      />
    </ListItem>
  );
};

const StoreSection = ({store, instructions}) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        p: 4,
        borderRadius: 3,
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: "background.paper",
      }}
    >
      <Box mb={4}>
        <Typography variant="h5" gutterBottom fontWeight="bold" color="primary">
          {store} Hediye Kodu Kullanımı
        </Typography>
        <Alert
          severity="info"
          sx={{
            mb: 3,
            borderRadius: 2,
            "& .MuiAlert-icon": {
              alignItems: "center",
            },
          }}
          icon={<Info color="primary" />}
        >
          <AlertTitle sx={{fontWeight: "bold"}}>Önemli Hatırlatma</AlertTitle>
          Kodlar kişiye özel olarak tanımlanmıştır ve tek kullanımlıktır.
        </Alert>
      </Box>

      <List>
        {instructions.map((instruction, index) => (
          <Fade in timeout={500 + index * 100} key={index}>
            <div>
              <InstructionItem {...instruction} />
              {index < instructions.length - 1 && (
                <Divider variant="middle" sx={{my: 2}} />
              )}
            </div>
          </Fade>
        ))}
      </List>
    </Paper>
  );
};

export const GiftCodesInstructions = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const migrosInstructions = [
    {
      icon: Store,
      text: "Mağazada ve Online Kullanım",
      subtitle:
        "Migros, 5M Migros, Macrocenter ve Migros Jet mağazalarında ve Sanal Market'te geçerlidir.",
      chipText: "Tüm Kanallar",
    },
    {
      icon: Payment,
      text: "Ödeme Detayları",
      subtitle:
        "Sanal markette kodun %80'i dijital kod ile, kalan %20'si kart ile ödenmelidir.",
      chipText: "80/20 Kural",
    },
    {
      icon: LocalOffer,
      text: "Harcama Limiti",
      subtitle: "Mağazada 200 TL'lik kod için 200 TL'lik harcama yapılabilir.",
      chipText: "Tam Değer",
    },
    {
      icon: Block,
      text: "Kullanım Kısıtlamaları",
      subtitle: "Altın, Gsm TL Yüklemesi ve Toplu ürün alımlarında kullanılamaz.",
      chipText: "Kısıtlamalar",
    },
    {
      icon: AccessTime,
      text: "Süre Sınırı",
      subtitle: "Dijital Alışveriş Kodlarının kullanım süresi bulunmamaktadır.",
      chipText: "Süresiz",
    },
  ];

  const a101Instructions = [
    {
      icon: Store,
      text: "Mağazada ve Online Kullanım",
      subtitle: "A101 mağazalarında ve online mağazada geçerlidir.",
      chipText: "Tüm Kanallar",
    },
    {
      icon: Payment,
      text: "Ödeme Detayları",
      subtitle:
        "Online mağazada kodun %80'i dijital kod ile, kalan %20'si kart ile ödenmelidir.",
      chipText: "80/20 Kural",
    },
    {
      icon: LocalOffer,
      text: "Harcama Limiti",
      subtitle: "Mağazada 200 TL'lik kod için 200 TL'lik harcama yapılabilir.",
      chipText: "Tam Değer",
    },
    {
      icon: Block,
      text: "Kullanım Kısıtlamaları",
      subtitle: "Altın, Gsm TL Yüklemesi ve Toplu ürün alımlarında kullanılamaz.",
      chipText: "Kısıtlamalar",
    },
  ];

  return (
    <Container maxWidth="lg" sx={{py: 6}}>
      <Box textAlign="center" mb={6}>
        <Typography
          variant="h3"
          component="h1"
          gutterBottom
          fontWeight="bold"
          color="primary"
          sx={{
            fontSize: {xs: "2rem", md: "3rem"},
            mb: 3,
          }}
        >
          Hediye Kodları Nasıl Kullanılır?
        </Typography>
        <Typography
          variant="subtitle1"
          color="text.secondary"
          sx={{maxWidth: "600px", mx: "auto", mb: 4}}
        >
          Hediye kodlarınızı kullanmadan önce lütfen aşağıdaki talimatları dikkatlice
          okuyunuz.
        </Typography>
      </Box>

      <Paper
        elevation={0}
        sx={{
          borderRadius: 3,
          overflow: "hidden",
          border: `1px solid ${theme.palette.divider}`,
          mb: 4,
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            "& .MuiTab-root": {
              py: 2,
              px: 4,
              fontWeight: "medium",
            },
          }}
        >
          <Tab label="Migros" icon={<ShoppingCart />} iconPosition="start" />
          <Tab label="A101" icon={<Store />} iconPosition="start" />
        </Tabs>

        <Box sx={{p: {xs: 2, md: 4}}}>
          {tabValue === 0 && (
            <Fade in timeout={500}>
              <div>
                <StoreSection store="Migros" instructions={migrosInstructions} />
              </div>
            </Fade>
          )}
          {tabValue === 1 && (
            <Fade in timeout={500}>
              <div>
                <StoreSection store="A101" instructions={a101Instructions} />
              </div>
            </Fade>
          )}
        </Box>
      </Paper>

      <Box
        sx={{
          textAlign: "center",
          mt: 4,
          p: 3,
          bgcolor: "primary.main",
          borderRadius: 3,
          color: "white",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Yardıma mı ihtiyacınız var?
        </Typography>
        <Typography variant="body2">
          Hediye kodu kullanımı ile ilgili sorularınız için müşteri hizmetlerimize
          ulaşabilirsiniz.
        </Typography>
      </Box>
    </Container>
  );
};

export default GiftCodesInstructions;
