import React, {useState} from "react";
import {
  Fab,
  Box,
  Dialog,
  DialogContent,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  Tooltip,
  Zoom,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import emailjs from "emailjs-com";
import {useTranslation} from "react-i18next";

const HelpBubble = ({bottom = 0}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    name: Yup.string().required(t("RequiredLabel")),
    surname: Yup.string().required(t("RequiredLabel")),
    email: Yup.string().email(t("mustBeValidEmail")).required(t("RequiredLabel")),
    message: Yup.string().required(t("RequiredLabel")),
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setMessage(null);
  };

  const handleSubmit = async (values, {resetForm}) => {
    setLoading(true);
    setMessage(null);
    try {
      await emailjs
        .send("service_exohkma", "template_30tdzvu", values, "A2pgTFc93Cu0aXiC3")
        .then(result => {
          setMessage({type: "success", text: t("messageSent")});
          resetForm();
        });
    } catch (error) {
      setMessage({
        type: "error",
        text: t("messageSendError"),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title={t("needHelp")} placement="left" arrow TransitionComponent={Zoom}>
        <Fab
          color="primary"
          aria-label="help"
          onClick={handleOpen}
          sx={{
            position: "fixed",
            bottom: bottom + 24,
            right: 24,
            zIndex: 1000,
            background: "linear-gradient(135deg, primary.main, #ffffff)",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            color: "white",
            "&:hover": {
              background: "linear-gradient(135deg, #ffffff, primary.main)",
              boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.35)",
            },
            "&:active": {
              boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.2)",
            },
            transition: "all 0.3s ease",
            animation: "float 3s ease-in-out infinite",
            "@keyframes float": {
              "0%, 100%": {transform: "translateY(0px)"},
              "50%": {transform: "translateY(-6px)"},
            },
          }}
        >
          <HelpOutlineIcon sx={{fontSize: 28}} />
        </Fab>
      </Tooltip>

      {/* Contact Form Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" color="primary">
              {t("contactUs")}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {message && (
            <Alert severity={message.type} sx={{mb: 2}}>
              {message.text}
            </Alert>
          )}

          <Formik
            initialValues={{name: "", surname: "", email: "", message: ""}}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({errors, touched}) => (
              <Form>
                <Field
                  as={TextField}
                  label={t("name")}
                  name="name"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <Field
                  as={TextField}
                  label={t("surname")}
                  name="surname"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={touched.surname && Boolean(errors.surname)}
                  helperText={touched.surname && errors.surname}
                />
                <Field
                  as={TextField}
                  label={t("mail")}
                  name="email"
                  type="email"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <Field
                  as={TextField}
                  label={t("message")}
                  name="message"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={4}
                  variant="outlined"
                  error={touched.message && Boolean(errors.message)}
                  helperText={touched.message && errors.message}
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  endIcon={loading ? <CircularProgress size={20} /> : <SendIcon />}
                  disabled={loading}
                  sx={{mt: 2, fontWeight: "bold", textTransform: "none"}}
                >
                  {loading ? t("sending") : t("sendMessage")}
                </Button>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HelpBubble;
