import React, {useEffect, useState} from "react";
import {Container} from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {teamMemberService} from "src/services/team/team-member";
import {
  TeamMemberRewardRequest,
  TeamMemberRewardsStats,
} from "src/entities/team/team-member";
import {useCallback} from "react";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {LoadingButton} from "@mui/lab";

export const Rewards = () => {
  const {t, i18n} = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [rewardRequests, setRewardRequests] = useState([]);
  const [migrosButtonLoading, setMigrosButtonLoading] = useState(false);
  const [a101ButtonLoading, setA101ButtonLoading] = useState(false);
  const [ibanAmount, setIbanAmount] = useState("");
  const [rewardStats, setRewardStats] = useState(new TeamMemberRewardsStats({}));
  const [usedPoints, setUsedPoints] = useState(0); // Initial used points

  useEffect(() => {
    teamMemberService.getTeamMemberRewardsStats().then(stats => {
      setRewardStats(new TeamMemberRewardsStats(stats));
    });
    teamMemberService.listTeamMemberRewardRequests().then(response => {
      const requests = response.results.map(req => new TeamMemberRewardRequest(req));
      setRewardRequests(requests);
    });
  }, []);

  const useNavigateToHowToUseGiftCodes = () => {
    const router = useRouter();

    return useCallback(() => {
      router.push(paths.dashboard.howtousegiftcodes);
    }, [router]);
  };

  const navigateToHowToUseGiftCodes = useNavigateToHowToUseGiftCodes();

  const handleReceiveGift = (rewardType, amount) => {
    const payload = {
      amount: amount,
      type: rewardType,
    };
    teamMemberService.createTeamMemberRewardRequests(payload).then(response => {
      console.log(response);
    });
    rewardStats.decrementAvailableRewardAmount(amount);
    setRewardStats(rewardStats.clone());
  };

  const handleIbanChange = event => {
    setIbanAmount(event.target.value);
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{py: 4}}>
        {/* Points Boxes */}
        <Grid container spacing={isMobile ? 2 : 4} sx={{mb: 4}}>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.availablePoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.available_reward_amount}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.pendingPoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.pending_reward_amount}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#ffffff",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                {t("rewards.usedPoints")}
              </Typography>
              <Typography variant="h4" sx={{mt: 1}}>
                {rewardStats.used_reward_amount}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Rewards Section */}
        <Typography variant="h5" sx={{mb: 2}}>
          {t("rewards.rewards")}
        </Typography>
        <Button onClick={navigateToHowToUseGiftCodes} color="primary">
          {t("Hediye Kodları Nasıl Kullanılır?")}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            flexWrap: "wrap",
            justifyContent: isMobile ? "center" : "space-between",
            gap: 2,
            mb: 4,
          }}
        >
          {/* Reward Cards */}
          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: isMobile ? "100%" : "30%",
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">Migros {t("rewards.giftCard")}</Typography>
            <Typography variant="body1" color="primary">
              {t("rewards.amount")} 100
            </Typography>
            <LoadingButton
              disabled={rewardStats.available_reward_amount < 100}
              loading={migrosButtonLoading}
              variant="contained"
              onClick={() => handleReceiveGift("migros", 100)}
            >
              {t("rewards.receiveGifts")}
            </LoadingButton>

            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  sx={{backgroundColor: "#ad1445", mt: 2}}*/}
            {/*  onClick={() => handleReceiveGift("migros", 100)}*/}
            {/*  disabled={rewardStats.available_rewards < 100}*/}
            {/*>*/}
            {/*  {t("rewards.receiveGifts")}*/}
            {/*</Button>*/}
          </Box>

          <Box
            sx={{
              backgroundColor: "#ffffff",
              padding: 2,
              borderRadius: 2,
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              width: isMobile ? "100%" : "30%",
              mx: isMobile ? 0 : 1,
              mb: isMobile ? 2 : 0,
            }}
          >
            <Typography variant="h6">A101 {t("rewards.giftCard")}</Typography>
            <Typography variant="body1" color="primary">
              {t("rewards.amount")} 200
            </Typography>
            <Button
              variant="contained"
              sx={{backgroundColor: "#ad1445", mt: 2}}
              onClick={() => handleReceiveGift("a101", 200)}
              disabled={rewardStats.available_reward_amount < 200}
            >
              {t("rewards.receiveGifts")}
            </Button>
          </Box>

          {/*<Box*/}
          {/*  sx={{*/}
          {/*    backgroundColor: "#ffffff",*/}
          {/*    padding: 2,*/}
          {/*    borderRadius: 2,*/}
          {/*    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",*/}
          {/*    textAlign: "center",*/}
          {/*    width: isMobile ? "100%" : "30%", // Adjusted width for 3 cards*/}
          {/*    mx: isMobile ? 0 : 1,*/}
          {/*    mb: isMobile ? 2 : 1,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography variant="h6">A101 {t("rewards.giftCard")}</Typography>*/}
          {/*  <Typography variant="body1" color="primary">*/}
          {/*    {t("rewards.amount")} 100*/}
          {/*  </Typography>*/}
          {/*  <Button*/}
          {/*    variant="contained"*/}
          {/*    sx={{backgroundColor: "#ad1445", mt: 2}}*/}
          {/*    onClick={() => handleReceiveGift("A101 Hediye Kartı", 100)}*/}
          {/*    disabled={rewardStats.available_rewards < 100}*/}
          {/*  >*/}
          {/*    {t("rewards.receiveGifts")}*/}
          {/*  </Button>*/}
          {/*</Box>*/}
          {/*<Box*/}
          {/*  sx={{*/}
          {/*    backgroundColor: "#ffffff",*/}
          {/*    padding: 2,*/}
          {/*    borderRadius: 2,*/}
          {/*    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",*/}
          {/*    textAlign: "center",*/}
          {/*    width: isMobile ? "100%" : "30%", // Adjusted width for 3 cards*/}
          {/*    mx: isMobile ? 0 : 1,*/}
          {/*    mb: isMobile ? 2 : 1,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Typography variant="h6">{t("rewards.sendToIban")}</Typography>*/}
          {/*  <Typography variant="body1" color="primary">*/}
          {/*    {t("rewards.amount")}*/}
          {/*    <TextField*/}
          {/*      sx={{ml: 1, width: "50%"}}*/}
          {/*      size="small"*/}
          {/*      variant="outlined"*/}
          {/*      value={ibanAmount}*/}
          {/*      onChange={handleIbanChange}*/}
          {/*    />*/}
          {/*  </Typography>*/}
          {/*  <Button*/}
          {/*    variant="contained"*/}
          {/*    sx={{backgroundColor: "#ad1445", mt: 2}}*/}
          {/*    onClick={() => handleReceiveGift("IBAN'a Gönder", ibanAmount)}*/}
          {/*    disabled={*/}
          {/*      !ibanAmount || rewardStats.available_rewards < parseInt(ibanAmount)*/}
          {/*    }*/}
          {/*  >*/}
          {/*    {t("rewards.receiveGifts")}*/}
          {/*  </Button>*/}
          {/*</Box>*/}
        </Box>

        {/* Previous Reward Requests Section */}
        <Typography variant="h6" sx={{mb: 2}}>
          {t("rewards.previousRewardRequests")}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("rewards.requestedStatus")}</TableCell>
                <TableCell>{t("rewards.pointUsed")}</TableCell>
                <TableCell>{t("rewards.rewardType")}</TableCell>
                <TableCell>{t("rewards.giftCode")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rewardRequests.length > 0 ? (
                rewardRequests.map((request, index) => (
                  <TableRow key={index}>
                    <TableCell>{request.status}</TableCell>
                    <TableCell>{request.amount}</TableCell>
                    <TableCell>{request.type}</TableCell>
                    <TableCell>{request.code}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    {t("rewards.noRequests")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};
