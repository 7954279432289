export const enSurveyTranslations = {
  survey: "Survey",
  next: "Next",
  prev: "Previous",
  pageErrors: "There are errors. Check you answers.",
  requiredQuestionError: "Required Question!",
  integerInputTypeError: "Integer Input Is Required!",
  copySurveyURL: "Copy Survey URL",
  copySurveyURLSuccessful: "Survey URL Copied",
  other: "Other",
  otherOptionEmptyError: "Other option cannot be empty.",
  nextPageWarningMessage:
    "Please answer all questions or check for errors. You need to provide the required information to proceed.",
  scrollDownWarningMessage:
    "Please scroll down to the bottom of the page and review all the information.",
};
